/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import { Link } from 'gatsby'
// import './styled.css'

const Testimonials = () => {
  const TestimonialsImages = useStaticQuery(graphql`
      query TestimonialsImages {
        anton: file(absolutePath: {regex: "/Anton_pic_square.png/"}) {
          childImageSharp {
            sizes(maxWidth: 250) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        first: file(absolutePath: {regex: "/CareersimageTopLeft.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1439) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        second: file(absolutePath: {regex: "/CareersImageMiddle.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1208) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        third: file(absolutePath: {regex: "/CareersImageTopRight.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1464) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        fourth: file(absolutePath: {regex: "/LouisLoop.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 720) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        fifth: file(absolutePath: {regex: "/CareersImageMiddleTwo.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1208) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        sixth: file(absolutePath: {regex: "/CareersImageBttmRight.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 959) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        world: file(absolutePath: {regex: "/world.png/"}) {
          childImageSharp {
            sizes(maxWidth: 959) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <div className="bg-black text-white mt-8 spb-5">
        <Container>
          <Row className="pt-5">
            <Col md="12" className="testimonial">
              <p>WORK FROM ANYWHERE</p>
              <h1>
                Work from home,  <br/>
                or from wherever you want.
              </h1>
              <p>
                Urbian has always been experimental and iterative: A place <br/>
                where you can unleash your curiosity, your passion for craft, and your drive <br/>
                to solve hard problems surrounded by like-minded people. We’re <br/>
                hiring people from everywhere, to design the future together <br/>
                whether with companies or through our ventures - a future that is <br/>
                Digital by Design.
              </p>
            </Col>
          </Row>
          <Row className="spt-8 testimonial testimonial-bubble">
            <Col md="12">
              <Image
                sizes={TestimonialsImages.world.childImageSharp.sizes}
              />
              <div className="test-border bg-light text-dark rounded-7 pb-8">
                <h5>
                  “For me waking up every day to work with people who genuinely get a kick out of building amazing products as much as I do is so much fun for me.”
                </h5>
              </div>
              <div class="circle-wrapper">
                <Image className="img-fluid" sizes={TestimonialsImages.anton.childImageSharp.sizes} alt="Anton Moulder"/>
              </div>
              <span className="attribution my-4">
                Anton Moulder, Founder & Managing Partner
              </span>
            </Col>
          </Row>

        <Row className="spb-8 culture-main-image-grid">
          <div className="image-wrapper">
            <Image
                sizes={TestimonialsImages.first.childImageSharp.sizes}
              />
          </div>
          <div className="image-wrapper">
            <Image
                sizes={TestimonialsImages.second.childImageSharp.sizes}
              />
          </div>
          <div className="image-wrapper">
            <Image
                sizes={TestimonialsImages.third.childImageSharp.sizes}
              />
          </div>
          <div className="image-wrapper">
            <Image
                sizes={TestimonialsImages.fourth.childImageSharp.sizes}
              />
          </div>
          <div className="image-wrapper">
            <Image
                sizes={TestimonialsImages.fifth.childImageSharp.sizes}
              />
          </div>
          <div className="image-wrapper">
            <Image
                sizes={TestimonialsImages.sixth.childImageSharp.sizes}
              />
          </div>
        </Row>
        </Container>
      </div>
    )
  }

export default Testimonials
