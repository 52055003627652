/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import { Link } from 'gatsby'

const ReferSomeone = () => {
  const ReferSomeone = useStaticQuery(graphql`
      query ReferSomeone {
        ten: file(absolutePath: {regex: "/FistbumpEmoji.png/"}) {
          childImageSharp {
            sizes(maxWidth: 959) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <div className="bg-black text-white mt-8 spb-5">
        <Container>
          <Row className="pt-5">
            <Col md="12" className="testimonial">
              <p className="text-yellow">REFER SOMEONE & GET R10,000</p>
              <h1>
                Not looking to move, <br/>
                But know someone awesome who is?
              </h1>
              <p>
                If you know someone who you think would be a great fit for us send <br/> them a link to this page or the specific role page, then send us an <br/> email with their full name and email address.
              </p>
              <p>
                We’ll pay you R10,000 once they’ve passed their first 3 months of <br/> employment.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md="3" className="center pt-4">
              <Image className="img-fluid" sizes={ReferSomeone.ten.childImageSharp.sizes} alt="10k"/>    
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

export default ReferSomeone
