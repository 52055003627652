/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import './styled.css'

const Lookinside = () => {
  const LookinsideArticleImages = useStaticQuery(graphql`
      query LookinsideArticleImages {
        baker: file(absolutePath: {regex: "/assets/images/1_z0tU-bZu_msgWrXAeLVlUw.jpeg/"}) {
          childImageSharp {
            sizes(maxWidth: 595) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        remote: file(absolutePath: {regex: "/assets/images/ConfessionsOfARemoteWorker.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        musings: file(absolutePath: {regex: "/assets/images/Anton10kftViewMusings.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        lazy: file(absolutePath: {regex: "/assets/images/WeLoveLazyCoders.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <div className="Lookinside spb-10">
        <Container className="">
          <Row className="sp-3">
            <Col lg="12">
              <h2>Take a look inside</h2>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <a href="/read/from-baker-to-maker">
                <div className="Articles-img">
                  <Image className="img-fluid" sizes={LookinsideArticleImages.baker.childImageSharp.sizes} alt="From a Baker To a Maker"/>
                </div>
                <div class="card-body">
                  <h6 className="h3">From a Baker To a Maker</h6>
                  <p>I went from baking to technology. This is a complete jump, an absolute change in direction. No one would even think such a thing is possible.</p>
                </div>
              </a>
            </Col>
            <Col sm="6">
              <a href="/read/confessions-of-a-remote-worker">
                <div className="Articles-img">
                  <Image className="img-fluid" sizes={LookinsideArticleImages.remote.childImageSharp.sizes} alt="Confessions of a Remote Worker"/>
                </div>
                <div class="card-body">
                  <h6 className="h3">Confessions of a Remote Worker</h6>
                  <p>Each one of us I believe will own our ‘new normal’. For me, this period of isolation has uncovered some key answers in working remotely from team members. Mainly, through these unique circumstances, virtual has become a mainstream channel to communicate.</p>
                </div>
              </a>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <a href="/read/antons-most-popular-10,000ft-view-musings">
                <div className="Articles-img">
                  <Image className="img-fluid" sizes={LookinsideArticleImages.musings.childImageSharp.sizes} alt="Anton’s Most Popular 10,000 ft View Musings"/>
                </div>
                <div class="card-body">
                  <h6 className="h3">Anton’s Most Popular 10,000 ft View Musings</h6>
                  <p>A collection of the best short musings from Urbian’s founder and managing partner, Anton Moulder.</p>
                </div>
              </a>
            </Col>
            <Col sm="6">
              <a href="/read/why-we-love-lazy-coders">
                <div className="Articles-img">
                  <Image className="img-fluid" sizes={LookinsideArticleImages.lazy.childImageSharp.sizes} alt="Why We Love Lazy Coders"/>
                </div>
                <div class="card-body">
                  <h6 className="h3">Why We Love Lazy Coders</h6>
                  <p>Lazy in this context doesn’t necessarily mean that you’re a human sloth uninterested in taking part in any from of productive action. In a work context, most of the time it actually means you’re actually rather smart.</p>
                </div>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
export default Lookinside
