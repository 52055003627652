/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import './styled.css'

import flex from '../../../assets/images/flex.svg'
import learning from '../../../assets/images/glasses.svg'
import holiday from '../../../assets/images/holiday.svg'
import cowork from '../../../assets/images/cowork.svg'
import device from '../../../assets/images/device.svg'
import parental from '../../../assets/images/parental.svg'
import pace from '../../../assets/images/pace.svg'
import travel from '../../../assets/images/car.svg'
import pie from '../../../assets/images/pie.svg'

class Ourbenefits extends React.Component {
  render() {
    return (
      <div class="Ourbenefits_min spb-8 ben">
        <Container>
        <Row>
          <Col lg="3">
            <h2>Our benefits</h2>
          </Col>
          <Col lg="9">
            <Row>
              <Col md="4">
                <div class="icobx"><img src={flex} alt="Flexible hours & location" /></div>
                <h6>Flexible hours & location</h6>
                <p>We allow you to work from wherever you like and allow you to choose the hours when you get your best work done.</p>
              </Col>
              <Col md="4">
                <div class="icobx"><img src={learning} alt="Unlimited learning stipend" /></div>
                <h6>Unlimited learning stipend</h6>
                <p>We pay 100% of all expenses for job-related training. We also pay 100% of expenses when you speak at conferences and events.</p>
              </Col>
              <Col md="4">
                <div class="icobx"><img src={cowork} alt="Coworking space stipend" /></div>
                <h6>Coworking space stipend</h6>
                <p>Don't feel like working from home all the time? No sweat. We will pay up to R3000/month towards renting co-working space in your area.</p>
              </Col>

              <Col md="4">
                <div class="icobx"><img src={device} alt="Choose your setup" /></div>
                <h6>Choose your setup</h6>
                <p>You’ll receive all new equipment including a laptop, monitor, and any other accessories you need to do your best work.</p>
              </Col>
              <Col md="4">
                <div class="icobx"><img src={holiday} alt="Unlimited holidays" /></div>
                <h6>Unlimited holidays</h6>
                <p>No, really. We’re about outcomes over hours, how much holiday you feel you need is up to you.</p>
              </Col>
              <Col md="4">
                <div class="icobx"><img src={parental} alt="Parental leave" /></div>
                <h6>Parental leave</h6>
                <p>We offer new parents 6 weeks of paid time to spend with the new family. Biological, adoptive and foster parents are all eligible for parental leave as well.</p>
              </Col>

              <Col md="4">
                <div class="icobx"><img src={pace} alt="Sustainable pace" /></div>
                <h6>Sustainable pace</h6>
                <p>We regularly stick to 40 hours a week, with the very occasional emergency push demanding more.</p>
              </Col>
              <Col md="4">
                <div class="icobx"><img src={travel} alt="Travel allowance" /></div>
                <h6>Travel allowance</h6>
                <p>Whether you drive yourself or catch an Uber for anything work related we cover the costs.</p>
              </Col>
              <Col md="4">
                <div class="icobx"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-seam" viewBox="0 0 16 16">
  <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z"/>
</svg></div>
                <h6>Snacks</h6>
                <p>We deliver snacks monthly so you never go hungry.</p>
              </Col>
            </Row>
          </Col>
        </Row>
        </Container>
      </div>
    )
  }
}

export default Ourbenefits
