/* eslint-disable */
import React from 'react'
import Layout from '../components/Layout'
import {
  CVSection,
  Lookinside,
  Topsection,
  Ourculture,
  Ourbenefits,
  HireProcess,
  Testimonials,
  ReferSomeone,
  Currentopenjob
} from '../components/careers'
import Gettouch from '../components/Gettouch'
import { Helmet } from 'react-helmet'

class careers extends React.Component {
  render() {
    return (
      <div className="career_min">
        <Layout>
          <Helmet>
            <title>Urbian | Careers</title>
          </Helmet>
            <Topsection></Topsection>
            <Testimonials></Testimonials>
            <Ourculture></Ourculture>
            <CVSection></CVSection>
            <HireProcess></HireProcess>
            <Ourbenefits></Ourbenefits>
            <Currentopenjob></Currentopenjob>
            <ReferSomeone></ReferSomeone>
            <Lookinside></Lookinside>
          {/* <Gettouch></Gettouch> */}
        </Layout>
      </div>
    )
  }
}

export default careers
