/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import { Link } from 'gatsby'
import './styled.css'

const Topsection = () => {
    const topCareer = useStaticQuery(graphql`
      query TopCareer {
        first: file(absolutePath: {regex: "/CareersimageTopLeft.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1439) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        second: file(absolutePath: {regex: "/CareersImageTopMiddle.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1208) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        third: file(absolutePath: {regex: "/AntonVideoLoop.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1464) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <Container className="introduction content-wrap">
        <Row className="sp-5">
          <Col md="8" lg="7" xl="6">
            <h1>Do the best work of your life, wherever you are.</h1>
            <p>
              Join us on our mission to launch products, services, and companies that have a meaningful impact on the world.
            </p>
            <p>
            <a className="styled__Link-lfEBUk link-button" href="#openings">
              Check our current openings
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down mx-15" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                </svg>
              </a>
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="career-image-grid">
          	<div className="image-wrapper"> 	
              <Image
                sizes={topCareer.first.childImageSharp.sizes}
              alt="Do the best work of your life"/>
          	</div>
          	<div className="image-wrapper">   
              <Image
                sizes={topCareer.second.childImageSharp.sizes}
              alt="We're on a mission to launch products, services, and
              companies that have a meaningful impact on the world"/>
          	</div>
          	<div className="image-wrapper">
              <Image
                sizes={topCareer.third.childImageSharp.sizes}
              alt="Do more than design and build incredible products. Help ship them, too. Because for us, work is only as good as its impact in the wild."/>
          	</div>
          </Col>
        </Row>
      </Container>
    )
  }

export default Topsection
