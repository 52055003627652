/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'
import './styled.css'

class Currentopenjob extends React.Component {
  render() {
    return (
      <Container className="Currentopenjob spb-8" id="openings">
        <Row>
          <Col lg="5" id="current-openings">
            <h2>
              2 current open <br /> positions
            </h2>
          </Col>
          <Col lg="7">
            <div className="border-bottom pb-5">
              <h4>Product Manager <small className="new-bubble">NEW</small></h4>
              <p>We’re looking for a Product Manager with a strong technical background who can rally teams of engineers and product designers, and drive the development of great products. You will sit at the intersection of business, tech, and design. You should feel as comfortable outlining a product strategy as discussing front-end architecture with engineers.</p>

              <p>Remote  •  Full-time  •  Posted 11 Nov 2021</p>

              <Link target="_blank" className="styled__Link-lfEBUk iUBhrC font-weight-bold"
                  to="https://urbian.notion.site/Product-Manager-04cb409533934728bb1b6e0a74416676">
                  Read more
                  <svg
                    className="styled__Arrow-fdeFVt fRPwvC"
                    width="14"
                    height="10"
                    xmlns="http://www.w3.org/2000/svg">
                    <g stroke="#030303"
                      fill="none"
                      fillRule="evenodd">
                      <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                      <path
                        d="M12.5 5.5H.5"
                        strokeLinecap="square"
                      ></path>
                    </g>
                  </svg>
                </Link>
            </div>

            <div className="spb-5 border-bottom">
              <h4>Junior Visual Designer / Motion Graphics <small className="new-bubble">NEW</small></h4>
              <p>You will have 3+ years working in a visual design and motion graphics role preferably in an agency. You will be responsible for creating case study video’s, animations to be used in digital products and social media as well as the design of presentations.</p>

              <p>Remote  •  Contract  •  Posted 11 Nov 2021</p>

              <Link target="_blank" className="styled__Link-lfEBUk iUBhrC font-weight-bold"
                  to="https://urbian.notion.site/Junior-Visual-Motion-Designer-143e643a4e9547f9935acb1a3af8ba1b">
                  Read more
                  <svg
                    className="styled__Arrow-fdeFVt fRPwvC"
                    width="14"
                    height="10"
                    xmlns="http://www.w3.org/2000/svg">
                    <g stroke="#030303"
                      fill="none"
                      fillRule="evenodd">
                      <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                      <path
                        d="M12.5 5.5H.5"
                        strokeLinecap="square"
                      ></path>
                    </g>
                  </svg>
                </Link>
            </div>


            <div className="spb-5">
              <h4>Looking for something else?</h4>
              <p>If you don’t see a position here and still think you could be a fit, feel free to apply for a general position.</p>

              <Link target="_blank" className="styled__Link-lfEBUk iUBhrC font-weight-bold"
                  to="https://urbian.notion.site/General-position-c8cd42ba8e004408bd040fdfcbd37d11">
                  Read more
                  <svg
                    className="styled__Arrow-fdeFVt fRPwvC"
                    width="14"
                    height="10"
                    xmlns="http://www.w3.org/2000/svg">
                    <g stroke="#030303"
                      fill="none"
                      fillRule="evenodd">
                      <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                      <path
                        d="M12.5 5.5H.5"
                        strokeLinecap="square"
                      ></path>
                    </g>
                  </svg>
                </Link>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Currentopenjob
