/* eslint-disable */
import 'bootstrap/dist/css/bootstrap.min.css'

import Image from 'gatsby-image'
import styled from 'styled-components'

import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'
import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'

import useOnScreen from '../../../hooks/useOnScreen'

const HireProcess = () => {
  const ref = useRef()
  const lifeRef = useRef()
  const faqsRef = useRef()
  const processRef = useRef()
  const isVisible = useOnScreen(ref)
  const lifeActive = useOnScreen(lifeRef) && !isVisible
  const processActive = useOnScreen(processRef) && !lifeActive && !isVisible
  const faqsActive = useOnScreen(faqsRef) && !processActive

  return (
    <div className="bg-light">
      <Container className="Currentopenjob spb-8">
        <Row className="d-flex">
          <Col md="6" className="visible-xs bg-light sticky-top pt-3 pb-3">
              <CategoriesWrapper>
                <a href="#hire">Who we hire</a>
                <a href="#life">The Life Story Interview</a>
                <a href="#process">Our hiring process</a>
                <a href="#faqs">FAQ's</a>
              </CategoriesWrapper>
          </Col>
          <Col md="6" className="hidden-xs">
            <div className="sticky-top pt-5">
              <div className="border-left">
                <div className="d-block "><a className={"pl-4 " + (isVisible && "h5 border-left-4")} href="#hire">Who we hire</a></div>
                <div className="d-block pt-3"><a className={"pl-4 " + (lifeActive && "h5 border-left-4")} href="#life">The life story interview</a></div>
                <div className="d-block pt-3"><a className={"pl-4 " + (processActive && "h5 border-left-4")} href="#process">Our hiring process</a></div>
                <div className="d-block pt-3"><a className={"pl-4 " + (faqsActive && "h5 border-left-4")} href="#faqs">FAQ's</a></div>
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="" id="hire" ref={ref}>
              <p>Who we hire</p>
              <h4>Growth mindsets over comfort zones</h4>
              <p>As we grow, we’re committed to nurturing a culture of trust and autonomy. We’re humble about what we know and what we don’t, and we’re looking for people who can champion that mindset with their peers and managers alike.</p>
            </div>
            <div className="spb-8 mt-2" id="life" ref={lifeRef}>
              <p>The Life Story interview</p>
              <h4>Let’s get to know each other</h4>
              <p>You're more than just the accomplishments on your CV. The Life Story interview is a two-sided conversation where we'll cover your professional and personal experience so far. You can expect us to ask questions that touch on these four topics.</p>
            </div>
            <Row>
              <Col md="6">
                <div className="pb-5">
                  <h5>Trust & Teamwork</h5>
                  <p>Are you someone who works well on your own asynchronously while also having high levels of open communication with your team?</p>
                </div>
                <div className="pb-5">
                  <h5>Self-awareness</h5>
                  <p>Do you have a decent understanding of your strengths and weaknesses and how that impacts those around you?</p>
                </div>
              </Col>
              <Col md="6">
                <div className="pb-5">
                  <h5>Independent Thinker</h5>
                  <p>Are you curious about the world? Given enough time are you able to find creative solutions to problems when they occur?</p>
                </div>
                <div className="pb-5">
                  <h5>Ownership & Impact</h5>
                  <p>Do you have a high a degree of care for what you do in work and life and are genuinely interested in making an impact?</p>
                </div>
              </Col>
            </Row>
            <div id="process" className="spb-8" ref={processRef}>
              <p>Our hiring process</p>
              <h4>From submit to success</h4>
              <p>From application to employment offer in as little as 2-3 weeks.</p>

              <h5>1. Apply</h5>
              <p>Select 1–2 roles that you believe are the best fit for yourself and your career. Don’t forget to check the requirements before you apply, as each role may have different ones.</p>


              <h5>2. Initial Interview • 15-30mins</h5>
              <p>Depending on the position, we may set up a 15–30 minute phone or virtual conversation with you, where you can get more info on our company, the work we do, the position and our hiring process. If you’re still keen on the position after the discussion you will go directly into a technical test before heading to The Life Story interview.</p>

              <h5>3. Technical Test • 1hr</h5>
              <p>We send you a link to a technical test made up of a few simple questions and short code challenges.</p>

              <h5>4. Life Story interview • 1hr</h5>
              <p>We're after way more than accomplishments on a CV. This is a one hour, conversation-style interview designed to help us get to know you and for you to get to know us. This is a two-sided conversation where we cover topics that are professional and personal (for the more introverted folks, this part can be as personal as you want it to be).
              </p>
              <p> The best way to prep is to spend some time thinking about experiences that have been formative to the point you’re at today.</p>

              <h5>5. Meet the team • 1hr</h5>
              <p>You’ll meet with the managing partners and/or future co-workers. Depending on the position, we may ask how you’d solve a problem and communicate it to the team.
              </p>

              <h5>6. Decision</h5>
              <p>We will get back to you with our decision within 2-3 days which will either be our reasons for not hiring you or an offer of employment.
              </p>
            </div>
            <div id="faqs" className="pt-5 mt-2" ref={faqsRef}>
              <p>FAQ's</p>
              <h4>Learn more about our hiring process</h4>
              <p>We are often able to go from application to employment offer in as little as 2-3 weeks.</p>

              <h4>How long is the hiring process typically?</h4>
              <p>We can have an employment offer in your inbox in as little as 2-3 weeks from receiving your application.</p>

              <h4>When can I expect to hear back after I have applied?</h4>
              <p>We reply to all applicants within 2 days.</p>

              <h4>I have applied/interviewed in the past and wasn’t selected. Can I apply again?</h4>
              <p>Absolutely! A lot can happen in 1-2 years, especially in developer roles.</p>

              <h4>I don’t quite fit the exact job requirements or experience level, should I still apply?</h4>
              <p>Of course! We hire people not CV’s. We are looking at you as a whole, if you find you are a year or two off the experience level requirement apply anyway. Often the most talented people don’t fit the mould.</p>

              <h4>If I’m hired, can you give me an idea of your company onboarding process?</h4>
              <p>Absolutely, have a <a href="">read about our general onboarding process.</a></p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const CategoriesWrapper = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  overflow-y: scroll;

  .active {
    border-bottom:3px solid #030303 !important;
    padding-bottom:3px !important;
    font-weight: 800;
  }
  
  a {
    font-size:1.1rem;
    margin:0 16px;
    white-space: nowrap;
    color: #030303 !important;
    font-weight: 500;
    padding-bottom:3px;
    border-bottom:3px solid white !important;

    :hover {
      color:#030303 !important;
      border-bottom:3px solid #030303 !important;
    }

    :nth-of-type(1) {
      margin-left:0;
    }
  }
`

export default HireProcess
