/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

const CVSection = () => {
  const CvImages = useStaticQuery(graphql`
  query CvImages {
    person: file(absolutePath: {regex: "/lady_headphones.jpeg/"}) {
      childImageSharp {
        sizes(maxWidth: 959) {
          ...GatsbyImageSharpSizes
        }
      }
    }
  }
`)
return (
  <Container className="Currentopenjob spb-8">
    <Row>
      <Col md="6" className="mb-4">
        <h2>We hire people, <br/> not CV's</h2>
        <p>The Life Story is our conversation-style interview approach. It's how we get to know you, and how you get to know us, so we can find out if we are going to work well together.</p>
      </Col>
      <Col md="6">
        <Image className="img-fluid" sizes={CvImages.person.childImageSharp.sizes} alt="Anton Moulder"/>
      </Col>
    </Row>
  </Container>
)
}

export default CVSection
